
import Vue from 'vue';

export default Vue.extend({
  name: 'linkedTypeCR',
  props: {
    enabled: Boolean,
  },
  computed: {
    statusText(): string {
      return this.enabled ? 'Enabled' : 'Disabled';
    },
    enabledIcon(): string {
      return this.enabled ? 'check_circle' : 'error';
    },
    enabledClass(): string {
      return this.enabled ? 'green--text' : 'red--text';
    },
  },
});
